import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './utils/scrollToTop';
import Hero from './components/Hero';
import { useLocation } from 'react-router-dom';


function App() {
  const currentLocation = useLocation();
  let header = ""
  switch (currentLocation.pathname){
    case "/contact":
      header = "Contact Us"
      break;
    case "/about":
      header = "About Us"
      break;
    case "/services":
      header = "Our Services"
      break;
    case "/team":
      header = "Our Team"
      break;
    case "/services/assetManagement":
      header = "Asset Management"
      break;
    case "/services/consulting":
      header = "Consulting"
      break;
    case "/services/i&dIntroductions":
      header = "Investor/Divestor Introductions"
      break;
  }

  return (
    <>
    <ScrollToTop />
    <Header
      companyName="Perygrene Ltd"
    />
    <Hero
      title={header}
    />
    <Outlet />
    
    <Footer 
      address="1418A 14, Thao Dien Street, Thao Dien Ward, District 2, Ho Chi Minh City, Vietnam"
      mobile="+84 903839983"
      email="contact@perygrene.vn"
    />
    </>
  );
}

export default App;
