import { NavLink } from "react-router-dom";
import React from "react";
import assets from "../assets/img/assets.png";
import consulting from "../assets/img/consultation.png";
import introductions from "../assets/img/relations.png";

export default function Service(){
  return (
    <div className="container-fluid bg-light mt-5 py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Services</div>
            <h1 className="mb-4">Our Extensive Experience for Your Business</h1>
            <p className="mb-4">
              Our primary expertise and experience is in pursuing and then managing assets from offshore,
              onshore, operated, non-operated oil, gas and power. Recently we have been involved in
              upstream, LNG, CCS/CCUS, offshore wind and electric vehicle consulting. We have
            </p>
            <p>
              We work to add value to clients' energy sector direct investment opportunities in Vietnam
              and regionally, working with them on their foreign direct and/or domestic initiatives.
            </p>
          </div>
          <div className="col-lg-7">
            <div className="row g-4">
              <div className="col-md-6">
                <div className="row g-4">
                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <img src={consulting} style={{"width": "30px", "height": "30px"}} alt="services"/>
                      </div>
                      <h5 className="mb-3">Consulting</h5>
                      <NavLink className="btn px-3 mt-auto mx-auto" to="/services/consulting">Read More</NavLink>
                    </div>
                  </div>
                  <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">""
                        <img src={assets} style={{"width": "30px", "height": "30px"}} alt="services"/>
                      </div>
                      <h5 className="mb-3">Asset Management</h5>
                      <NavLink className="btn px-3 mt-auto mx-auto" to="/services/assetManagement">Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row g4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                    <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                      <div className="service-icon btn-square">
                        <img src={introductions} style={{"width": "30px", "height": "30px"}} alt="services"/>
                      </div>
                      <h5 className="mb-3">Investor/divestor introductions</h5>
                      <NavLink className="btn px-3 mt-auto mx-auto" to={"/services/i&dIntroductions"}>Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}