import About from "../components/About";
import Service from "../components/Service";
import Team from "../components/Team";
import Experience from "../components/Experience";

export default function Main(){
  return (
    <>
      <About 
        paragraph1="Sustainability is at the forefront of Perygrene's activities and support to clients."
        paragraph2="We advise and introduce investors to renewable solutions, including power generation and energy storage, including solar, wind, hydrogen, liquid air and other technologies in Vietnam"
        paragraph3="We have expertise in the offshore wind, LNG and gas to power sector, carbon capture and storage, as well as the offshore oil and gas sectors."
      />
      <Service />
      <Experience />
      <Team />
    </>
  )
}