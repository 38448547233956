import { NavLink } from "react-router-dom";
import Member from "../models/member";
import Members from "./Member";
import team from "../data/team";

export default function Team(){
  return (
    <div className="container-fluid bg-light py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">

          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Team</div>
            <h1 className="mb-4">Meet Our Experienced Team Members</h1>
            <p className="mb-4">
              The team's experience spans the international oil, gas and power sectors, with NavLink strong focus
              on Vietnam where our experience spans development of Vietnam's gas industry and market
              from the first Gas Master Plan, the BP operated Nam Con Son Gas Project and Phu My 3
              Power Plant, gas sales negotiations (of both natural and associated offshore gas, 2018), and
              more recently the preparations for import of LNG and the emergence of renewables and dawn
              of offshore wind.
            </p>
            <NavLink className="btn btn-primary rounded-pill px-4" to={"/team"}>Read More</NavLink>
          </div>

          <div className="col-lg-7">
            <div className="row g-4">
              {team.map((member: Member)=> {
                return (
                  <Members {...member} />
                )
              })
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}