import { NavLink } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import perygrene from "../assets/img/perygrene.jpeg";

export default function Header(props: any){
  const [expanded, setExpanded] = useState("false");
  useEffect(() => {
    setExpanded("false");
  },[expanded])
  const handleToggle = () => {
    const navbarCollapse = document.getElementById("navbarCollapse");
    navbarCollapse?.classList.toggle("show");
    const navbarToggler = document.getElementsByClassName("navbar-toggler")[0];
    navbarToggler.setAttribute("aria-expanded", navbarCollapse?.classList.contains("show") ? "true" : "false");

  }
  const handleNavigation = () => {
    const navbarCollapse = document.getElementById("navbarCollapse");
    navbarCollapse?.classList.remove("show");
  }
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className="container-fluid sticky-top" style={{"position": "sticky", "top": 0, "zIndex": "1000", "backgroundColor": "#1363C6"}}>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <NavLink to={"/"} onClick={handleNavigation} className="navbar-brand d-flex align-items-center">
            <img src={perygrene} alt="logo" style={{"height": "60px", "width": "auto"}} />
          </NavLink>
          <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" onClick={handleToggle}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto">
              <NavLink to={"/about"} onClick={handleNavigation} className="nav-item nav-link">About Us</NavLink>
              <NavLink to={"/services"} onClick={handleNavigation} className="nav-item nav-link">Our Services</NavLink>
              <NavLink to={"/team"} onClick={handleNavigation} className="nav-item nav-link">Our Team</NavLink>
              <NavLink to={"/contact"} onClick={handleNavigation} className="nav-item nav-link">Contact Us</NavLink>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}