import aboutImg from "../../assets/img/asset.jpeg";
import consulting from "../../assets/img/alaska_drill.jpg";
import { NavLink } from "react-router-dom";

export default function AssetManagement(props: any){
  return (
    <>
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="about-img">
              <img className="img-fluid" alt="assets" src={aboutImg}/>
            </div>
          </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <h1 className="mb-4">{ props.header1 }</h1>
          <p className="mb-4">
            { props.paragraph1 }
          </p>
          <p className="mb-4">
            { props.paragraph2 }
          </p>
          <NavLink to={"/services"} className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Back to Our Services</NavLink>
        </div>
      </div>
    </div>
    </div>
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img">
            <img className="img-fluid" alt="assets" src={consulting}/>
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <h1 className="mb-4">{ props.header2 }</h1>
          <p className="mb-4">
            { props.paragraph3 }
          </p>
          <p className="mb-4">
            { props.paragraph4 }
          </p>
          <NavLink to={"/services"} className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Back to Our Services</NavLink>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}