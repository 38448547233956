import About from "../components/About";
import Experience from "../components/Experience";

export default function AboutRoute(){
  return (
    <>
    <About 
      paragraph1="We are a consultancy with primary strength and expertise in pursuing and managing energy related assets for/with investors."
      paragraph2="We advise and introduce investors to renewable solutions, including power generation and energy storage, including solar, wind, hydrogen, liquid air and other technologies in Vietnam"
      paragraph3="We have expertise in the offshore wind, LNG and gas to power sector, carbon capture and storage, as well as the offshore oil and gas sectors."
    />
    <Experience />
    </>
  )
}