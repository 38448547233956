import Member from "../models/member";

const team: Member[] = [
  {
    name: "Dr. Hugh Sykes",
    title: "Managing Director & Owner",
    description: "Dr Hugh Sykes is the Managing Director and owner of Perygrene. He has been involved in Vietnam's energy sector since 1995 when he participated in developing Vietnam's national gas master plan with Petrovietnam in Hanoi. Hugh has managed various investments, some from conception to divestment. His career to date spans various international roles and projects with BP, GEC Turbines, Mubadala Petroleum, Rolls Royce and Salamander Energy at locations in the UK, UAE, Vietnam, Indonesia and Australia. Hugh is resident in Vietnam and enjoys a broad network of contacts across the energy sector, particularly in offshore petroleum operations, investors, Government and industry.",
    imageURL: "./assets/img/team-1.jpg",
    numberOfLinks: 4,
    links: ["https://britchamvn.com/", "https://www.spe.org/en/", "https://www.imeche.org/", "https://www.aien.org/"],
    linkedIn: "https://vn.linkedin.com/in/hugh-sykes-20804810",
  },
  {
    name: "Ms. Nguyen Thi Thu Nga",
    title: "Director & Legal Representative",
    description: "Ms Nguyễn Thị Thu Nga commenced her work in Vietnam's energy sector in 2009 when she joined Pearl Energy in Ho Chi Minh City. Nga has over ten years Joint Venture coordination experience in petroleum with an international energy company, is a qualified lawyer and has been the in country compliance and ethics champion for a foreign investor. Nga has worked extensively with senior executives both in foreign investors and Government entities, including Ministries and Embassies. Detailed working knowledge of Vietnamese business formalities and approvals for foreign investors.",
    imageURL: "./assets/img/team-1.jpg",
    numberOfLinks: 1,
    links: ["https://aien.org/"],
    linkedIn: "",
  },
  {
    name: "Mr. Phan Quang",
    title: "MITTACO M.D. Tax & Finance",
    description: "Mr. Quang has over 20 years of experience in taxation, finance and had held senior tax positions in MNCs, with large investments in Vietnam (BP, Rosneft) and large Vietnamese and MNC consortia (Masangroup, Vingroup, Central Group & TNI King Coffee Group). Quang has deep, practical understanding of various industries including energy, petroleum, mining, retail, education, medicare, hospitality, automobiles, FMCGs and animal feeds. He had also worked for Big Four firms in Vietnam (EY, Coopers & Lybrand) focusing on investment and finance taxation. Quang graduated from Ho Chi Minh City University of Economics, specialized in Finance and holds a post degree on Management from Central Queensland University, Australia. Mr Quang is currently the Managing Director of Minh Tam Consultancy Services Limited (MITACCO), a boutique consulting firm offering investment, finance, tax and customs consultation.",
    imageURL: "./assets/img/team-1.jpg",
    numberOfLinks: 1,
    links: ["https://britchamvn.com/"],
    linkedIn: "",
  },
  {
    name: "Mr. Tran Quang Hoang",
    title: "Technical Director",
    description: "Mr Hoan has over 30 years experience as a geoscientist, recently leaving Mubadala Petroleum as exploration manager in Vietnam. Hoan has worked for BP, Unocal (Chevron) and Petrovietnam with assignments including Malaysia. Hoan holds a Masters degree from Brunei. His expertise spans exploration work from frontier to infrastructure-led programmes as well as reserves estimation and reporting.",
    imageURL: "./assets/img/team-1.jpg",
    numberOfLinks: 0,
    links: [],
    linkedIn: "",
    }
  ];

export default team;