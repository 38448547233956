import Member from "../models/member";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import britCharm from '../assets/img/britcharm.png';
import spe from '../assets/img/spe.jpg';
import iome from '../assets/img/iome.png';
import aien from '../assets/img/aien.png';
import memberImage from "../assets/img/team-1.jpg";

const checkImage = (link: string) => {
  if (link === "https://britchamvn.com/") {
    return britCharm;
  } else if (link === "https://www.spe.org/en/") {
    return spe;
  } else if (link === "https://www.imeche.org/") {
    return iome;
  } else {
    return aien;
  }
}
export default function Members(props: Member){
  const popover = (member: Member): JSX.Element =>  (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{member.name}</Popover.Header>
      <Popover.Body>
        <strong className="fs-6">{member.description}</strong>
        <div className="row h-100 justify-content-center">
          {props.links.map((link: string) => {
            return (
              <div className="col-sm-6 col-lg-4 col-xl-3 text-xl-start"><a href={link} rel="noreferrer" target='_blank'> <img className="mb-4" 
                src={checkImage(link)} alt="members" style={{height: "25px", width: "50px"}}/></a></div>
            )
          })}
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
    <div className="col-md-6">
      <div className="row g-4">
        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
          <div className="team-item bg-white text-center rounded p-4 pt-0">
            <img className="img-fluid rounded-circle p-4" src={memberImage} alt=""/>
            <h5 className="mb-0">{props.name}</h5>
            <small>{props.title}</small>
            <br />
            <br />
            <OverlayTrigger trigger={"click"}  placement="bottom" overlay={popover(props)}>
              <Button type="button" className="btn btn-outline-primary rounded-pill text-white px-4">Read More</Button>
            </OverlayTrigger>
            <div className="d-flex justify-content-center mt-3">
              <a className="btn btn-square btn-primary m-1" rel="noreferrer" target={"_blank"} href={props.linkedIn}><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}