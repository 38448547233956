import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import consulting from "../assets/img/test2.jpg";

export default function Hero(props: any){

  const currentLocation = useLocation();
  let isHome = false
  if (currentLocation.pathname === "/"){
    isHome = true
  }
  return (
    <>
    {isHome ?
    <div className="container-fluid pt-5 bg-primary hero-header mb-5" style={{backgroundImage: `url(${consulting})`}}>
      <div className="container pt-5">
        <div className="row g-5 pt-5" style={{ backgroundColor: 'rgba(0,0,0, 0.4)'}}>
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5 text-white" >
            <p className="mb-4 animated slideInRight">
              Perygrene applies international experience in LNG, gas, power and a motivated team seeking
              to support the flourishing energy sector in Vietnam. Alongside technical and operational
              experience, the team has extensive asset management, including, tax and finance expertise
              and experience.
            </p>
            <NavLink to={"/contact"} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</NavLink>
          </div>
          <br/>
        </div>
      </div>
    </div>
  :
  <div className="container-fluid pt-5 bg-primary hero-header"style={{backgroundImage: `url(${consulting})`}}>
    <div className="container pt-5">
        <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                <h1 className="display-4 text-white mb-4 animated slideInRight">{props.title}</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                        <li className="breadcrumb-item"><NavLink className="text-white" to={"/"}>Home</NavLink></li>
                        <li className="breadcrumb-item text-white active" aria-current="page">{props.title}</li>
                    </ol>
                </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
            </div>
        </div>
    </div>
  </div>
  }
  </>
  )
}