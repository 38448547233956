import { NavLink } from "react-router-dom";

export default function Footer(props: any){
  return (
    <div className="container-fluid bg-dark text-white-50 footer pt-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
            <NavLink to={"/"} className="d-inline-block mb-3">
              <h1 className="text-white">Perygrene Ltd</h1>
            </NavLink>
            <p className="mb-0">
              Perygrene Ltd was established, or 'hatched' in Vietnam at the Department of Planning &
              Investment in Ho Chi Minh City on 27th November 2021, shortly after the city emerged from
              a long Covid-19 lockdown.
            </p>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p><i className="fa fa-map-marker-alt me-3"></i>{props.address}</p>
            <p><i className="fa fa-phone-alt me-3"></i>{props.mobile}</p>
            <p><i className="fa fa-envelope me-3"></i><a href={`mailto:${props.email}`}>{props.email}</a></p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" target={"_blank"} rel={"noreferrer"} href={"https://www.linkedin.com/company/c%C3%B4ng-ty-tnhh-perygrene/?originalSubdomain=vn"}><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
            <h5 className="text-white mb-4">Popular Link</h5>
            <NavLink className="btn btn-link" to={"/about"}>About Us</NavLink>
            <NavLink className="btn btn-link" to={"/contact"}>Contact Us</NavLink>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
            <h5 className="text-white mb-4">Our Services</h5>
            <NavLink className="btn btn-link" to={"/services/assetManagement"}>Asset Management</NavLink>
            <NavLink className="btn btn-link" to={"/services/consulting"}>Consulting</NavLink>
            <NavLink className="btn btn-link" to={"/services/i&dIntroductions"}>Investor/divestor introductions</NavLink>
          </div>
        </div>
      </div>
        <div className="container wow fadeIn" data-wow-delay="0.1s">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">&copy; 
                      <NavLink className="border-bottom" to={"/"}>Perygrene Ltd</NavLink>, All Right Reserved.
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                      <div className="footer-menu">
                        <NavLink to={"/"}>Home</NavLink>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}