import { NavLink } from "react-router-dom";
import consulting from "../../assets/img/presentation.png";
import Experience from "../../components/Experience";

export default function Consulting(props: any){
    return (
      <>
        <div className="container-fluid py-5">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="about-img">
                  <img className="img-fluid" alt="about" src={consulting} style={{"width": "570px"}}/>
                </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 className="mb-4">{ props.header1 }</h1>
                <p className="mb-4">
                  { props.paragraph1 }
                </p>
                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>{props.paragraph2}</h6>
                
                <br />
                <NavLink to={"/services"} className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Back to Our Services</NavLink>
              </div>
            </div>
          </div>
        </div>
        <Experience />
      </>
    )
}